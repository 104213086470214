<template>
    <page-content>
        <template v-slot:summary>
            Professional software design and development, approaching each project with pragmatic minds and fresh enthusiasm.
        </template>

        <template v-slot:watermark>
            <lucid-wordmark />
            <lucid-code />
        </template>

        <p>
            We are analytical and critical thinkers with insatiable appetites for knowledge and growth, constant learners who 
            fully understand that change never stops, progress never ends, and education is never irrelevant.  We have an incredible 
            understanding of software development concepts and are fluent in all aspects of analysis and design. our ongoing 
            commitment to software design and architecture help us to grow that understanding each and every day.
        </p>

        <div class="next-steps">
            Learn more about <router-link to="services">our services</router-link>.
        </div>

    </page-content>
</template>

<script>
    import Content from '@/components/Content';
    import LucidQs from '@/assets/lucidqs.svg';
    import Code from '@/assets/code.svg';

    export default {
        components: {
            'page-content': Content,
            'lucid-wordmark': LucidQs,
            'lucid-code': Code
        }    
    }
</script>

<style scoped lang="scss">
    .next-steps {
        margin-top: 3em;
    }
</style>
